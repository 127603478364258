/**
 * Module dependencies.
 */

import { Social, fundacaoSocialNetworks, termsUrl, visitPortugalSocialNetworks } from 'src/core/content-config/footer';

import { media } from 'src/styles/media';
import { useRouter } from 'next/router';
import Container from 'src/components/core/layout/container';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import RawHtml from 'src/components/core/raw-html';
import Svg from 'src/components/core/svg';
import logo from 'src/assets/svg/logo.svg';
import map from 'lodash/map';
import styled from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useTranslate from 'src/hooks/use-translate';
import visitPortugalLogo from 'src/assets/svg/visit-portugal.svg';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.footer`
  background-color: var(--color-dark);
  padding: 3.75rem 0 6rem;

  ${media.min.md`
    padding: 1.875rem 0 6rem;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  ${media.min.md`
    display: grid;
    grid-template-areas:
      '. visitPortugal fundacao .'
      '. links         links .'
      '. copyright     copyright .';
    grid-template-columns: 1fr repeat(2, 5fr) 1fr;
  `}

  ${media.min.xl`
    display: grid;
    grid-template-areas:
      '. visitPortugal fundacao .'
      '. copyright     links .';
    grid-template-columns: 1fr repeat(2, 5fr) 1fr;
  `}
`;

/**
 * `VisitPortugalWrapper` styled component.
 */

const VisitPortugalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: visitPortugal;
  grid-gap: 1.75rem;
  position: relative;

  ${media.min.md`
    border-bottom: 1px solid var(--color-white-opacity-10);
    border-right: 1px solid var(--color-white-opacity-10);
    flex-direction: row;
    grid-gap: 3rem;
    padding: 3.125rem 0 2rem;

    &:before {
      background-color: var(--color-white-opacity-10);
      bottom: -1px;
      content: '';
      height: 1px;
      left: -1.5rem;
      position: absolute;
      width: 1.5rem;
    }
  `}

  ${media.min.xxl`
    grid-gap: 6rem;
  `}

  ${media.max.md`
    margin-bottom: 5.25rem;
  `}
`;

/**
 * `FundacaoWrapper` styled component.
 */

const FundacaoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: fundacao;
  grid-gap: 1.75rem;
  justify-content: flex-end;
  position: relative;

  ${media.min.md`
    border-bottom: 1px solid var(--color-white-opacity-10);
    flex-direction: row;
    grid-gap: 3rem;
    padding: 3.125rem 0 2rem;

    &:before {
      background-color: var(--color-white-opacity-10);
      bottom: -1px;
      content: '';
      height: 1px;
      right: -1.5rem;
      position: absolute;
      width: 1.5rem;
    }
  `}

  ${media.min.xxl`
    grid-gap: 6rem;
  `}

  ${media.max.md`
    margin-bottom: 5.25rem;
  `}
`;

/**
 * `SocialLink` styled component.
 */

const SocialLink = styled.a`
  color: var(--color-grey-800);
  cursor: pointer;
  text-decoration: none;
  transition: color var(--transition-default);

  &:focus,
  &:hover {
    color: var(--color-white);
  }
`;

/**
 * `SocialWrapper` styled component.
 */

const SocialWrapper = styled.div`
  align-items: center;
  color: var(--color-grey-600);
  display: flex;
  grid-gap: 1.25rem;

  ${media.min.md`
    grid-gap: 1.125rem;
 `}
`;

/**
 * `SocialLabel` styled component.
 */

const SocialLabel = styled.p`
  font-family: var(--font-futura-pt);
  font-size: 0.9375rem;
  line-height: 1.25rem;
  margin-right: 0.875rem;
`;

/**
 * `CopyrightWrapper` styled component.
 */

const CopyrightWrapper = styled.div`
  color: var(--color-grey-600);
  grid-area: copyright;
  padding-top: 3.125rem;
  text-align: center;

  ${media.min.md`
    align-self: center;
    display: flex;
    justify-content: center;
 `}

  ${media.min.xl`
    justify-content: flex-start;
 `}
`;

/**
 * `Copyright` styled component.
 */

const Copyright = styled.p`
  font-family: var(--font-futura-pt);
  font-size: 0.9375rem;
  line-height: 1.25rem;

  ${media.min.md`
    &:first-of-type {
      margin-right: 0.5rem;
    }
  `}
`;

/**
 * `FooterLinkList` styled component.
 */

const FooterLinkList = styled.ul`
  align-items: center;
  color: var(--color-grey-600);
  display: flex;
  grid-area: links;
  grid-gap: 3rem;
  justify-content: center;

  ${media.min.md`
    grid-gap: 2rem;
    padding-top: 3.125rem;
 `}

  ${media.min.xl`
    justify-content: flex-end;
    grid-gap: 2rem;
 `}

  ${media.max.md`
    flex-direction: column;
 `}
`;

/**
 * `FooterLinkItem` styled component.
 */

const FooterLinkItem = styled.li`
  position: relative;

  ${media.min.md`
    &::after {
      background-color: var(--color-grey-600);
      content: '';
      height: 1rem;
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  `}
`;

/**
 * `FooterLink` styled component.
 */

const FooterLink = styled.a`
  align-items: center;
  color: inherit;
  color: var(--color-grey-600);
  cursor: pointer;
  display: flex;
  font-family: var(--font-futura-pt);
  font-size: 1.15rem;
  letter-spacing: 0;
  line-height: 1.15rem;
  margin: 0;
  text-decoration: none;
  transition: color var(--transition-default);

  ${media.min.md`
    font-size: 0.9rem;
    line-height: 0.9rem;
 `}

  &:focus,
  &:hover {
    color: var(--color-white);
  }
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled.a`
  color: var(--color-white);
  cursor: pointer;
  text-decoration: none;
  transition: opacity var(--transition-default);

  &:focus,
  &:hover {
    opacity: 0.7;
  }
`;

/**
 * `Footer` component.
 */

const Footer = () => {
  const { translate } = useTranslate();
  const isMobile = useBreakpoint('md', 'max');
  const isSmallDesktop = useBreakpoint('xl', 'max');
  const router = useRouter();

  return (
    <Wrapper>
      <Container>
        <Grid>
          <VisitPortugalWrapper>
            <FadeInAnimation
              options={{
                threshold: 1,
                transitionTime: 1
              }}
            >
              <LogoLink href={'https://www.visitportugal.com/'} rel={'noopener noreferrer'} target={'_blank'}>
                <Svg icon={visitPortugalLogo} size={'12.8125rem'} />
              </LogoLink>
            </FadeInAnimation>

            <FadeInAnimation
              options={{
                threshold: 1,
                transitionTime: 1
              }}
            >
              <SocialWrapper>
                {!isSmallDesktop && <SocialLabel>{translate('common:footer.socialNetworksLabel')}</SocialLabel>}

                {map(
                  visitPortugalSocialNetworks,
                  ({ href, icon, title }: Social, index: number) =>
                    href && (
                      <SocialLink href={href} key={index} rel={'noopener'} target={'_blank'} title={title}>
                        <Svg icon={icon} size={isMobile ? '1.8rem' : '1.6rem'} />
                      </SocialLink>
                    )
                )}
              </SocialWrapper>
            </FadeInAnimation>
          </VisitPortugalWrapper>

          <FundacaoWrapper>
            <FadeInAnimation
              options={{
                threshold: 1,
                transitionTime: 1
              }}
            >
              <LogoLink href={'https://www.josesaramago.org/'} rel={'noopener noreferrer'} target={'_blank'}>
                <Svg icon={logo} size={'9.25rem'} />
              </LogoLink>
            </FadeInAnimation>

            <FadeInAnimation
              options={{
                threshold: 1,
                transitionTime: 1
              }}
            >
              <SocialWrapper>
                {!isSmallDesktop && <SocialLabel>{translate('common:footer.socialNetworksLabel')}</SocialLabel>}

                {map(
                  fundacaoSocialNetworks,
                  ({ href, icon, title }: Social, index: number) =>
                    href && (
                      <SocialLink href={href} key={index} rel={'noopener'} target={'_blank'} title={title}>
                        <Svg icon={icon} size={isMobile ? '1.8rem' : '1.6rem'} />
                      </SocialLink>
                    )
                )}
              </SocialWrapper>
            </FadeInAnimation>
          </FundacaoWrapper>

          <FooterLinkList>
            <FooterLinkItem>
              <FadeInAnimation
                options={{
                  threshold: 1,
                  transitionTime: 1
                }}
              >
                <FooterLink
                  // @ts-expect-error locale is defined
                  href={termsUrl[router.locale]}
                  rel={'noopener'}
                  target={'_blank'}
                >
                  {translate('common:footer.termsPolicy')}
                </FooterLink>
              </FadeInAnimation>
            </FooterLinkItem>
          </FooterLinkList>

          <CopyrightWrapper>
            <FadeInAnimation
              options={{
                threshold: 1,
                transitionTime: 1
              }}
            >
              <RawHtml element={Copyright}>
                {translate(`common:footer.allRightsReserved${isMobile ? 'Mobile' : ''}`, {
                  year: new Date().getFullYear()
                })}
              </RawHtml>
            </FadeInAnimation>
          </CopyrightWrapper>
        </Grid>
      </Container>
    </Wrapper>
  );
};

/**
 * Export `Footer` component.
 */

export default Footer;
