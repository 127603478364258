/**
 * Module dependencies.
 */

import { FC, ReactNode, createElement } from 'react';

/**
 * `RawHtmlProps` interface.
 */

interface RawHtmlProps {
  children: ReactNode;
  className?: string;
  element?: any;
}

/**
 * `RawHtml` component.
 */

const RawHtml: FC<RawHtmlProps> = (props: RawHtmlProps) => {
  const { children, element, ...rest } = props;

  return createElement(element ?? 'span', {
    ...rest,
    dangerouslySetInnerHTML: { __html: children } // eslint-disable-line id-match
  });
};

/**
 * Export `RawHtml` component.
 */

export default RawHtml;
