/**
 * Module dependencies.
 */

import { regexes } from './regexes';

/**
 * Export `isExternalUrl` util.
 */

export const isExternalUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }

  return regexes.url.test(url) || regexes.emailLink.test(url) || regexes.telLink.test(url);
};
