/**
 * Module dependencies.
 */

import { NavbarTheme } from 'src/types/navbar';
import { Waypoint } from 'react-waypoint';
import { useNavbarTheme } from 'src/context/navbar-theme/context';
import React, { ReactElement, useCallback, useEffect, useRef } from 'react';

/**
 * `Props` type.
 */

type Props = {
  colorTheme: NavbarTheme;
  horizontalNavigation?: boolean;
};

/**
 * Export `useInitialNavbarTheme` hook.
 */

export function useInitialNavbarTheme(initialTheme: NavbarTheme) {
  const { setTheme } = useNavbarTheme();

  useEffect(() => {
    setTheme(initialTheme);
  }, [initialTheme, setTheme]);
}

/**
 * `usePreviousTheme` hook.
 */

function usePreviousTheme(theme: NavbarTheme) {
  const ref = useRef<NavbarTheme>();

  useEffect(() => {
    ref.current = theme;
  });

  return ref.current;
}

/**
 * `TriggerNavbarTheme` component.
 */

const TriggerNavbarTheme = ({ colorTheme, horizontalNavigation }: Props): ReactElement => {
  const { setTheme, theme } = useNavbarTheme();
  const previousTheme = usePreviousTheme(theme);
  const handleEnter = useCallback(
    ({ currentPosition, previousPosition }: Waypoint.CallbackArgs) => {
      const isAbove = previousPosition === Waypoint.above;
      const isInside = currentPosition === Waypoint.inside;

      if (isAbove && isInside) {
        setTheme(previousTheme ?? colorTheme);
      }
    },
    [colorTheme, previousTheme, setTheme]
  );

  const handleLeave = useCallback(
    ({ currentPosition, previousPosition }: Waypoint.CallbackArgs) => {
      const isAbove = currentPosition === Waypoint.above;
      const isInside = previousPosition === Waypoint.inside;

      if (isAbove && isInside) {
        setTheme(colorTheme);
      }
    },
    [colorTheme, setTheme]
  );

  const handleEnterHorizontal = useCallback(
    ({ currentPosition, previousPosition }: Waypoint.CallbackArgs) => {
      const isAbove = previousPosition === Waypoint.above;
      const isInside = currentPosition === Waypoint.inside;

      if (isAbove && isInside) {
        setTheme(colorTheme ?? previousTheme);
      }
    },
    [colorTheme, previousTheme, setTheme]
  );

  const handleLeaveHorizontal = useCallback(
    ({ currentPosition, previousPosition }: Waypoint.CallbackArgs) => {
      const isAbove = currentPosition === Waypoint.above;
      const isInside = previousPosition === Waypoint.inside;

      if (isAbove && isInside) {
        setTheme(colorTheme);
      }
    },
    [colorTheme, setTheme]
  );

  return (
    <Waypoint
      bottomOffset={0}
      horizontal={horizontalNavigation}
      onEnter={horizontalNavigation ? handleEnterHorizontal : handleEnter}
      onLeave={horizontalNavigation ? handleLeaveHorizontal : handleLeave}
      topOffset={horizontalNavigation ? 0 : 100}
    />
  );
};

/**
 * Export `TriggerNavbarTheme` component.
 */

export default TriggerNavbarTheme;
